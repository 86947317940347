/**
 * IMPORTS
 */

import React from 'react';

import { useTranslation } from '../../utils/translate';
import SpinningWheel from '../common/SpinningWheel';

/**
 * CORE
 */

function Upload() {
  const { t } = useTranslation();

  return (
    <SpinningWheel text={t('common.uploading') as string} />
  )
}

export default Upload;

/**
 * IMPORTS
 */

import React, { useContext } from 'react';
import { AspectRatio, Stack, Image, Button, Container, Text } from '@chakra-ui/react';
import FallbackImage from '../common/FallbackImage';

import { useTranslation } from '../../utils/translate';
import CaptureCount from './CaptureCount';
import CaptureContext from './CaptureContext';

/**
 * CORE
 */

interface GuideStepProps {
  image: string;
  onValidateInstructions: () => any;
}

function GuideStep({
  image,
  onValidateInstructions,
}: GuideStepProps) {
  const { t } = useTranslation();
  const { captureIndex } = useContext(CaptureContext);
  
  return (
    <Stack spacing="1rem">
      <AspectRatio ratio={1.25}>
        <Image
          src={image}
          fallback={<FallbackImage />}
        />
      </AspectRatio>
      
      <CaptureCount />
      
      <Container>
        <Stack spacing="1rem">
          <Text>
            {t([`capture_${captureIndex}.instructions`, 'capture.instructions'])}
          </Text>
          <Button
            colorScheme="brand"
            size="lg"
            onClick={onValidateInstructions}
            isFullWidth
          >
            {t([`capture_${captureIndex}.instructions-button`, 'capture.instructions-button'])}
          </Button>
        </Stack>
      </Container>
    </Stack>
  );
}

export default GuideStep;

/**
 * IMPORTS
 */

import { Text, Box, Stack, ButtonGroup, IconButton, AspectRatio, Container, Center, Heading, Button } from '@chakra-ui/react';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FaDownload, FaFacebook, FaLinkedin, FaPlay, FaWhatsapp } from 'react-icons/fa';
import { connect, ConnectedProps } from 'react-redux';

import { MSState } from '../../reducers';
import { getPickedContent, getSessionId } from '../../selectors';
import Header from '../common/Header';
import { useTranslation } from '../../utils/translate';
import FullHeightFlex from '../common/FullHeightFlex';
import Footer from '../common/Footer';
import config from '../../utils/config';
import MinHSpacer from '../common/MinHSpacer';

/**
 * TYPES
 */

declare global {
  // eslint-disable-next-line
  interface HTMLVideoElement {
    webkitRequestFullscreen?: HTMLVideoElement['requestFullscreen'];
    webkitEnterFullscreen?: HTMLVideoElement['requestFullscreen'];
    mozRequestFullScreen?: HTMLVideoElement['requestFullscreen'];
    msRequestFullscreen?: HTMLVideoElement['requestFullscreen'];
  }
}

/**
 * UTILS
 */

function getShareUrl(id: string, productId: string) {
  return `${window.location.origin}/share/${id}/${productId}`;
}

function windowOpen(url: string) {
  const config = {
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
  };

  return window.open(url, '', (Object.keys(config) as Array<keyof typeof config>).map((k) => `${k}=${config[k]}`).join(','));
}

/**
 * CORE
 */

const connector = connect(
  (state: MSState) => ({
    id: getSessionId(state),
    content: getPickedContent(state),
  }),
  {

  },
);

function CTA() {
  const { t } = useTranslation();
  
  const handleClick = useCallback(() => {
    const uri = t('output.cta-link') as string;
    if (uri) {
      window.open(uri, '_self');
    }
  }, [t]);

  return (
    <Button
      size="md"
      colorScheme="brand"
      onClick={handleClick}
    >
      {t('output.cta')}
    </Button>
  );
}

type ShareScreenProps = ConnectedProps<typeof connector> & {};

function ShareScreen({
  id,
  content,
}: ShareScreenProps) {
  const [isFullscreen, setFullscreen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { t } = useTranslation();

  const handleDownload = useCallback(() => {
    if (content?.download) {
      window.open(content.download, '_self');
    }
  }, [content?.download]);

  const handleWhatsapp = useCallback(() => {
    if (id && content?.productId) {
      const text = encodeURIComponent(getShareUrl(id, content.productId));
      windowOpen(`https://wa.me/?text=${text}`);
    }
  }, [id, content?.productId]);

  const handleLinkedin = useCallback(() => {
    if (id && content?.productId) {
      const url = encodeURIComponent(getShareUrl(id, content.productId));
      windowOpen(`https://linkedin.com/shareArticle?url=${url}&mini=true`);
    }
  }, [id, content?.productId]);

  const handleFacebook = useCallback(() => {
    if (id && content?.productId) {
      const url = encodeURIComponent(getShareUrl(id, content.productId));
      windowOpen(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
    }
  }, [id, content?.productId]);

  useEffect(() => {
    const onFullscreenChange = () => setFullscreen(val => !val);
    document.addEventListener('fullscreenchange', onFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, []);

  const handlePlay = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    videoRef.current.muted = false;
    videoRef.current.play();

    const fn = videoRef.current.requestFullscreen
      || videoRef.current.webkitRequestFullscreen /* Safari */
      || videoRef.current.mozRequestFullScreen    /* Mozilla */
      || videoRef.current.webkitEnterFullscreen   /* Safari */
      || videoRef.current.msRequestFullscreen;    /* IE11 */
    
    if (fn) {
      fn.call(videoRef.current);
    }
  }, [videoRef]);

  if (!id || !content) {
    return <div />
  };

  return (
    <FullHeightFlex direction="column">
      <Header />
      
      <MinHSpacer />

      <Container>
        <Stack spacing="1rem">
          <Heading textAlign="center">
            {t('output.title')}
          </Heading>

          <Text textAlign="center">
            {t('output.instructions')}
          </Text>
        </Stack>
      </Container>

      <MinHSpacer />

      <Center>
        <Box
          w="200px"
          position="relative"
        >
          <Box
            width="100%"
            borderRadius="25%"
            borderWidth="3px"
            borderColor="white"
            boxShadow="0px 3px 10px gray"
            // boxShadow="2xl"
            overflow="hidden"
            transform="translateZ(0)" // fix overflow:hidden + radius on safari -> triggers GPU
          >
            <AspectRatio ratio={1}>
              <video
                ref={videoRef}
                src={content.url}
                playsInline={!isFullscreen}
                muted={!isFullscreen}
                style={isFullscreen ? { objectFit: 'contain' } : {}}
                loop
                autoPlay
              />
            </AspectRatio>
            <Box
              position="absolute"
              w="100%"
              h="100%"
              top={0}
              left={0}
              onClick={handlePlay}
            >
              <Center h="100%">
                <Box
                  w={65}
                  h={65}
                  backgroundColor="blue.500"
                  borderRadius="50%"
                  boxShadow="0 0 10px rgba(0,0,0,0.5)"
                >
                  <Center h="100%">
                    <Text fontSize="2rem" color="white">
                      <FaPlay />
                    </Text>
                  </Center>
                </Box>
              </Center>
            </Box>
          </Box>
        </Box>
      </Center>

      <MinHSpacer />

      <Container>
        <Stack spacing="1rem">
          <Text textAlign="center">
            {t('output.share-instructions')}
          </Text>

          <Center>
            <ButtonGroup
              size="lg"
              isAttached
            >
              <IconButton
                aria-label="Download"
                icon={<FaDownload />}
                colorScheme="brand"
                disabled={!content.download}
                onClick={handleDownload}
              />
              <IconButton
                aria-label="Share on Facebook"
                colorScheme="facebook"
                icon={<FaFacebook />}
                onClick={handleFacebook}
              />
              <IconButton
                aria-label="Share on Whatsapp"
                colorScheme="whatsapp"
                icon={<FaWhatsapp />}
                onClick={handleWhatsapp}
              />
              <IconButton
                aria-label="Share on LinkedIn"
                colorScheme="linkedin"
                icon={<FaLinkedin />}
                onClick={handleLinkedin}
              />
            </ButtonGroup>
          </Center>

          {config.webapp?.cta && (
            <Center>
              <CTA />
            </Center>
          )}
        </Stack>
      </Container>

      <MinHSpacer />

      <Footer />
    </FullHeightFlex>
  );
}

export default connector(ShareScreen);

/**
 * IMPORTS
 */

import React from 'react';

import { useTranslation } from '../../utils/translate';
import SpinningWheel from '../common/SpinningWheel';

/**
 * CORE
 */

function WaitScreen() {
  const { t } = useTranslation();

  return (
    <SpinningWheel text={t('common.rendering') as string} />
  );
}

export default WaitScreen;

/**
 * IMPORTS
 */

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AspectRatio, Box, Button, Center, Container, Heading, Stack, Image } from '@chakra-ui/react';
import Webcam from 'react-webcam';
import { MdCameraAlt } from 'react-icons/md';

import { useTranslation } from '../../utils/translate';
import CaptureContext from './CaptureContext';

import frameRef from '../../assets/frame-ref.png';

/**
 * CORE
 */

interface CaptureStepProps {
  onTakePhoto: (dataUri: string) => any;
}

function CaptureStep({
  onTakePhoto,
}: CaptureStepProps) {
  const [started, setStarted] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [takePhoto, setTakePhoto] = useState(false);
  const webcamRef = useRef<Webcam & HTMLVideoElement>(null);

  const { captureIndex } = useContext(CaptureContext);

  const startCountdown = useCallback(() => setStarted(true), []);

  useEffect(() => {
    if (!started) return;

    if (countdown > 0) {
      const to = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(to);
    }

    setTakePhoto(true);
  }, [started, countdown]);

  useEffect(() => {
    if (takePhoto) {
      setTakePhoto(false);
      
      const webcam = webcamRef.current;
      if (!webcam) {
        alert('Camera is not initiliazed');
        return;
      }

      const { videoWidth = 100, videoHeight = 100 } = webcam.video || {};
      const ratio = videoHeight / videoWidth;
      const maxW = Math.min(videoWidth, 1080);
      const dimensions = { width: maxW, height: Math.round(maxW * ratio) };

      const dataUri = webcam.getScreenshot(dimensions);
      if (dataUri) {
        onTakePhoto(dataUri);
      }
    }
  }, [takePhoto, webcamRef, onTakePhoto]);

  const { t } = useTranslation();

  return (
    <Stack spacing="1rem">
      <Box
        position="relative"
        bgColor="grey"
      >
        <AspectRatio ratio={1}>
          <Webcam
            ref={webcamRef}
            audio={false}
            mirrored
            screenshotFormat="image/jpeg"
            // screenshotQuality={1}
            videoConstraints={{
              facingMode: "user",
              width: { ideal: 4096 },
            }}
          />
        </AspectRatio>

        <Box
          position="absolute"
          w="100%"
          h="100%"
          top={0}
          left={0}
        >
          <Image src={frameRef} opacity={0.5} />
        </Box>

        {started && countdown > 0 && (
          <Box
            position="absolute"
            w="100%"
            bottom="2rem"
            left={0}
          >
            <Center h="100%">
              <Heading
                size="4xl"
                color="white"
                textShadow="0 0 5px black"
              >
                {countdown}
              </Heading>
            </Center>
          </Box>
        )}
      </Box>

      <Container>
        <Button
          size="lg"
          colorScheme="brand"
          isFullWidth
          onClick={startCountdown}
          leftIcon={<MdCameraAlt />}
          disabled={started}
        >
          {t([`capture_${captureIndex}.capture-button`, 'capture.capture-button'])}
        </Button>
      </Container>
    </Stack>
  );
}

export default CaptureStep;

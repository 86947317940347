/**
 * IMPORTS
 */

import React from 'react';
import { CircularProgress, Text, Stack } from '@chakra-ui/react';

import FullHeightFlex from './FullHeightFlex';
import MinHSpacer from './MinHSpacer';

/**
 * CORE
 */

interface SpinningWheelProps {
  text: string; 
}

function SpinningWheel({ text }: SpinningWheelProps) {
  return (
    <FullHeightFlex direction="column">
      <MinHSpacer />

      <Stack align="center">
        <CircularProgress
          color="brand.500"
          isIndeterminate
        />
        <Text>
          {text}
        </Text>
      </Stack>

      <MinHSpacer />
    </FullHeightFlex>
  );
}

export default SpinningWheel;
